@import "./variables";


.mchat-container {
  border-top: 1px solid var(--mchat-header-text-color);
  box-sizing: border-box;
  display: block;
  max-height: 130px;
  transition: max-height 0.7s linear;
  font-size: var(--mchat-font-size);
  font-family: var(--mchat-font);
  line-height: 1em;
  display: flex;
  opacity: 1;
  z-index: 1;
  width: 100%;
  overflow-y: hidden;

  & *,
  & *:before,
  & *:after {
  box-sizing: border-box;
}

  &--closed {
    max-height: 132px;
  }

  // Scrollbar
  /* width */
  ::-webkit-scrollbar {
    width: 0.5em;
  }
  /* Track */
  // ::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 5px grey;
  // }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--mchat-primary);
    border-radius: 20px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--mchat-primary);
  }

  a {
    color: var(--mchat-primary);
  }
}

.mchat-container--closed {
  opacity: 0;
  max-height: 0px;
}

.mchat {
  position: relative;
  height: 100%;
  width: 100%;
  background: var(--mchat-background-color);
  color: var(--mchat-dark-text-color);
  display: flex;
  flex-direction: column;
}

.mchat-text-with-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.25em;
}

.mchat-primary-color {
  color: var(--mchat-primary);
}

.mchat-time-left-icon {
  background-color: var(--mchat-primary);
  -webkit-mask: url(../assets/time-left.svg) no-repeat center;
  mask: url(../assets/time-left.svg) no-repeat center;
  width: 1.5em;
  height: 1.5em;
}

.mchat-header {
  display: flex;
  align-items: center;
  color: var(--mchat-header-text-color);
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
  font-weight: bold;
}

.mchat-header__title {
  padding: 1em;
}

.mchat-body {
  position: relative;
  padding-bottom: 1em;
  display: flex;
  gap: 0.5em;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;
  overflow-x: visible;
  scroll-behavior: smooth;
  overscroll-behavior-y: contain;
  flex-grow: 1;

  & > * {
    max-width: 100%;
  }

  a {
    overflow-wrap: anywhere;
  }
}

.mchat-body--avatars .mchat-message:not(.mchat-text-message.mchat-message--in):not(.mchat-typing-message) {
  padding-left: 2.3em;
}

.mchat-flex {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  gap: 1em;
}

.mchat-bubble {
  display: flex;
  gap: 0.25em;
  position: relative;
  
  &--in {
    & .mchat-bubble__content {
      color: var(--mchat-incoming-bubble-text-color);
      background-color: var(--mchat-incoming-bubble-color);
      border-radius: 0 var(--mchat-radius) var(--mchat-radius);
      padding: 1em;
      line-height: 1.3rem;
    }
  }

  &--out {
    & .mchat-bubble__content {
      color: var(--mchat-outcoming-bubble-text-color);
      background-color: var(--mchat-outcoming-bubble-color);
      border-radius: var(--mchat-radius) 0 var(--mchat-radius)
        var(--mchat-radius);
      padding: 1em;
      line-height: 1.3em;  
    }
  }
}

.mchat-bubble__avatar {

  & > img {
    width: 2.5em;
    max-width: none;
  }
}

.mchat-bubble__action-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5;
}

.mchat-bubble__action {
  cursor: pointer;
  padding: 0.25rem 0;
}

.mchat-bubble__header {
  color: var(--mchat-bubble-header-color);
  padding-bottom: 0.25em;
}

.mchat-bubble__link {
  margin-top: 0.25em;
  display: block;
  color: var(--mchat-primary);
}

.mchat-image {
  margin-top: 8px;
  max-width: 408px;
}

.mchat-bubble__title {
  margin-bottom: 0.5em;
  font-size: 1.25em;
  font-weight: 600;
}

.mchat-input-section {
  position: relative;
  padding: 0.25em 1em 0.35em;
  display: flex;
  gap: 0.5em;
}

.mchat-input-section::before {
  content: ''; 
  display: block; 
  height: 2px; 
  opacity: 0.9;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.7);
  overflow-y: visible;
  margin: 0 auto; 
  position: absolute;  
  top: -4; 
  left: 1em;  
  right: 1em;   
}

.mchat-audio-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1em;
  font-size: 1.3em;
  font-weight: bold;
  border: none;
  color: var(--mchat-header-color);
}

.mchat-input-section__input {
  width: 100%;
  padding: 0.5em;
  padding-right: 0px;
  border-radius: var(--mchat-radius);
  border: none;
  background: transparent;
  color: hsl(var(--mchat-base-color-2), 0%, 0%);
  font-size: 1em;

  &:focus-visible,
  &:visited {
    outline: none;
  }

  &--multiline {
    resize: none;
    font-style: normal;
    border-radius: 0;
    margin: 0.25em 0em;
    padding: 0 0 0 0.25em;
    overflow: hidden;
    font-family: inherit;
    font-weight: normal;
    line-height: 1.3em;
  }
}

.mchat-input-section__input::placeholder {
  color: hsl(var(--mchat-base-color-2), 0%, 40%);
}

.mchat-action-button {
  border: 1px solid var(--mchat-button-color);
  border-radius: 10px;
  background-color: var(--mchat-button-color);
  color: var(--mchat-dark-text-color);
  font-size: 16px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  margin-top: 8px;
  transition: all 0.3s;

  &:hover {
    color: var(--mchat-light-text-color);
  }

  &:focus {
    border: 1px solid var(--mchat-light-text-color) 
  }
}

.mchat-action-button--secondary {
  border: 1px solid var(--mchat-primary);
  background-color: transparent;
  color: var(--mchat-primary);
}

.mchat-input-section__button {
  border: none;
  padding: 0.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  background: transparent;
  height: fit-content;
  align-self: center;

  > svg {
    position: relative;
    fill: var(--mchat-button-color);
    transition: all 0.3s;
  }
    
  @keyframes pulse {  
    0% {  
      transform: scale(1);  
      opacity: 1;  
    }  
    50% {  
      transform: scale(1.2);
      fill: hsl(var(--mchat-base-color-1), 48%, 30%);
      opacity: 0.8;  
    }  
    100% {  
      transform: scale(1);  
      opacity: 1;  
    }  
  }  

  & > svg:hover {
    fill: var(--mchat-selected-state-color);
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &--audio-active > svg {
    fill: hsl(var(--mchat-base-color-1), 48%, 30%);
    transform: scale(0.8);  
  }

  &--audio-pulsing > svg {
    animation: pulse 1s infinite;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
    &:hover {
      filter: none;
    }

    &:active {
      background-color: var(--mchat-user-input-section);
    }
  }

  &.mchat-input-section__button--input-character-counter:disabled {
    opacity: 1;
  }
}

.mchat-input-section__character-counter {
  color: red;
  padding: 0.7em 0;
  font-weight: bold;
}

.mchat-message {
  display: flex;
  gap: 0.5em;
  width: 100%;
  align-items: center;
  justify-content: center;

  &--out {
    flex-direction: row-reverse;
  }
}

.mchat-buttons-message {
  @media screen and (max-width: 700px) {
    align-items: center;
    padding-left: 0em;
  }
}

.mchat-hyperlinks-group-message {
  display: flex;
  flex-direction: row;
  gap: 0.3em;
  flex-wrap: wrap;

  & > * {
    min-width: 0;
    overflow-wrap: anywhere;
  }
}

.mchat-search-message {
  gap: 0.5em;
}

.mchat-typing-message {
  width: auto;
}

.mchat-typing-message__svg {
  width: 30px;
  fill: var(--mchat-incoming-bubble-text-color);
}

@keyframes typing {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(0);
  }
}

.mchat-typing-message__circle-1 {
  animation: typing 1s infinite;
}

.mchat-typing-message__circle-2 {
  animation: typing 1s infinite 0.2s;
}

.mchat-typing-message__circle-3 {
  animation: typing 1s infinite 0.4s;
}

.mchat-debug-message {
  font-size: 90%;
  color: white;

  .mchat-bubble {
    background-color: #333131;
    display: flex;
    flex-direction: column;
  }
}

.mchat-debug-message__title {
  vertical-align: top;
  padding: 0;
  margin: 0;
}

.mchat-debug-message__items {
  display: flex;
  flex-direction: column;
  word-break: break-all;
  list-style: none;
  padding-inline-start: 10px;
}

.mchat-closer {
  padding: 1em;
  cursor: pointer;
  border: none;
  background: var(--mchat-header-color);
  color: inherit;
}

.mchat-closer__icon {
  width: 2.5em;
  height: 2.5em;
  display: flex;
}

.mchat-opener {
  margin-bottom: 0px;
  margin-right: 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 65px;
  height: 65px;
  background-color: var(--mchat-primary);
  border-radius: 100%;
  align-self: flex-end;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--mchat-primary);
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;

  @media screen and (min-width: 1300px) {
    margin-bottom: 16px;
    margin-right: 16px;
    width: 90px;
    height: 90px;
    border: none;
  }
}

.mchat-opener__icon svg {
  color: white;
  width: 3.0em;
  height: auto;

  @media screen and (max-width: 1300px) {
    width: 2.250em;
  }
}

//LINKS

.mchat-hyperlink {
  display: flex;
  margin-top: 4px;
  font-style: italic;
  width: fit-content;
  text-decoration: none;
  transition: all 0.2s;
  gap: 0.5em;
  color: var(--mchat-incoming-bubble-text-color);
   
  &:hover {
    color: hsl(var(--mchat-base-color-1), 100%, 40%);
    transform: scale(1.02);
  }
}

// BUTTONS

.mchat {
  & .mchat-button {
    display: inline-block;
    border: none;
    border-radius: 10px;
    color: var(--mchat-button-text-color);
    background-color: var(--mchat-button-color);
    cursor: pointer;
    padding: 0.9em 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: .75em;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  
  &:hover {
    background-color: var(--mchat-selected-state-color)
  }

  }

  & .mchat-button--selected {
    background-color: var(--mchat-selected-state-color);
    color: var(--mchat-outcoming-bubble-text-color);
    pointer-events: none;
  }

  
  .mchat-icon-button {
    display: flex;
    gap: 0.5em;
    align-items: center;

  }

  .mchat-option-button:disabled:not(.mchat-button--selected) {
    background-color: var(--mchat-disabled-state-color);
    cursor: not-allowed;
  }
  
  .mchat-hyperlink-button{
    width: fit-content;
    text-decoration: none;
  }
}


.mchat-option-buttons {
  display: flex;
  gap: 0.5em;
  flex-direction: row;
  align-items: flex-start;
  max-width: fit-content;

  & > * {
    width: 100%;
  }
}


.mchat-audio {
  position: absolute;
  left: 23em;
  bottom: 3.25em;
  height: 50px;

  --steady {
    background-color: #f4d03f;
  }

  --go {
    background-color: #28b463;
  }
}

.mchat-user-prompt-panel {
  display: flex;
  margin-top: var(--mchat-header-height);
  align-items: center;
  justify-content: center;
  inset: 0;
  position: absolute;
  flex-grow: 1;
  z-index: 100;
  
  &::before {
    display: flex;
    animation: fadeIn 1s ease both;
    content: '';
    inset: 0;
    position: absolute;
    background-color: hsla(var(--mchat-secondary-base-color-hsl), 0.4);
    z-index: 101;
    backdrop-filter: blur(2px);

    @media screen and (min-width: 700px) {
      border-bottom-left-radius: var(--mchat-radius);
      border-bottom-right-radius: var(--mchat-radius);
    }    
  }
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.mchat-user-prompt-panel__body {
  width: 90%;
  position: relative;
  animation: fadeIn 1s ease both;
  display: flex;  
  z-index: 102;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 1em;
  background: radial-gradient(50% 50% at 50% 50%, hsla(var(--mchat-panel-background-color-hsl), 95%) 33.5%, hsl(var(--mchat-panel-background-color-hsl)) 100%);
  color: var(--mchat-panel-text-color);
  gap: 0.5em;
  word-wrap: break-word;

  & > * {
    filter: brightness(1.25);
  }
}


.mchat-user-prompt-panel__prompt {
  display: flex;
  gap: 0.25em;
  flex-direction: column;
}

.mchat-user-prompt-panel__actions {
  margin-top: 1em;
}

.mchat-user-prompt-panel__actions {
  margin-top: 1em;
  display: flex;
  gap: 10px;
}

.mchat-user-prompt-panel__content {
  white-space: pre-wrap;
  align-self: self-start;
  text-align: left;
  width: 100%;
  border-top: 1px dotted var(--mchat-primary);
  padding: 0.5em;
  padding-top: 1.0em;
  filter: brightness(0.8);
}

.mchat-feedback-message {
  max-height: 120px;
  line-height: 1.4em;
  background-color: var(--mchat-feedback-background-color);
  border-color: var(--mchat-primary);
  border: 1px solid var(--mchat-primary);
  color: var(--mchat-incoming-bubble-text-color);
  border-radius: var(--mchat-radius);
  padding: 1em;
  transition: all 0.2s ease;
  flex-direction: column;

  .mchat-feedback__values {
    margin-top: 10px;
  }
}

.mchat-feedback-message--compact {
  width: auto;
  border: none;
  background-color: inherit;
  border-radius: 0px;
  padding: 0.25em 0.25em 0.25em 0em;

  & .mchat-feedback__values {
    margin-top: 0px;
    padding-left: 0.5em;
    border-left: 1px solid var(--mchat-primary);
  }

  .mchat-feedback__comment {
    margin-left: 1em;
  }

  &.mchat-feedback-message--comment {
    width: 100%;
  }
}

.mchat-feedback-message--comment {
  max-height: 1000px;
}

.mchat-feedback__title {
  font-weight: bold;
}

.mchat-feedback__text {
  font-style: italic;
}

.mchat-feedback__values {
  display: flex;
  color: var(--mchat-feedback-value-color);
  justify-content: center;
  gap: 3px;
  position: relative;
  font-size: 1.5em;
}

.mchat-feedback__values--editable {
  cursor: pointer;

  &:hover {
    .mchat-feedback__value--star {
      color: var(--mchat-selected-state-color);
    }
  }

  .mchat-feedback__value--star {
    transition: color 0.3s ease;
    color: var(--mchat-incoming-bubble-text-color);

    &:hover {
      color: var(--mchat-selected-state-color);
    }
  }

  .mchat-feedback__value--star:hover ~ .mchat-feedback__value--star  {
    color: var(--mchat-incoming-bubble-text-color);
  }


  .mchat-feedback__value--thumb {
    font-size: 1.5em;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.2);
    }
  }

}  

.mchat-feedback__value--selected {
  color: var(--mchat-selected-state-color);

  &.mchat-feedback__value--numeric-scale {
    & > * {
    background-color: var(--mchat-selected-state-color);
    }
  }
}

.mchat-feedback__values--selected {
  animation: jump_feedback 250ms 1;
  color: var(--mchat-selected-state-color);
}

.mchat-feedback__comment {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-top: 10px;

  > * {
    margin: 0 auto;
  }

  &:before {
    content: '';
    width: 20%;
    height: 1px;
    background: var(--mchat-primary);
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mchat-feedback__comment-title {
  margin-top: 5px;
  margin-bottom: 5px;
}

@keyframes jump_feedback {
  0% {
    top: 0;
  }
  30% {
    top: -10px;
  }
  60% {
    top: 0;
  }
  80% {
    top: 5px;
  }
  100% {
    top: 0;
  }
}

.mchat-search-message_buttons {
  display: flex;
  gap: 0.5rem;
}
