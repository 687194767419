:root {
    // colors

    --mchat-base-color-1:0;
    --mchat-base-color-2:0;

    
    --mchat-secondary-base-color-hsl: var(--mchat-base-color-2), 0%, 0%; 

    --mchat-primary: hsl(var(--mchat-base-color-1),76%,45%);
    --mchat-secondary: hsl(var(--mchat-secondary-base-color-hsl));
    --mchat-terciary: #595f6e;
    --mchat-user-input-section: #8c919b;
    
    --mchat-light-text-color: hsl(0,100%,100%);
    --mchat-dark-text-color: hsl(0,0%,0%);

    --mchat-disabled-state-color: #8c919b;
    --mchat-selected-state-color: hsl(var(--mchat-base-color-1), 100%, 35%);
    // font
    --mchat-font: Open Sans, sans-serif;
    --mchat-font-size: 1.0rem;
    --mchat-radius: 25px;

    --mchat-bubble-header-color: #8c919b;
  
    --mchat-incoming-bubble-color: hsl(0, 0%, 90%);
    --mchat-incoming-bubble-text-color: var(--mchat-dark-text-color);

    --mchat-outcoming-bubble-color: var(--mchat-primary);
    --mchat-outcoming-bubble-text-color: var(--mchat-light-text-color);

    --mchat-header-height: 2em;
    --mchat-header-color: var(--mchat-secondary);
    --mchat-header-text-color: var(--mchat-dark-text-color);
    
    --mchat-background-color: transparent;

    --mchat-panel-background-color-hsl: var(--mchat-secondary-base-color-hsl);
    --mchat-panel-text-color: var(--mchat-light-text-color);

    --mchat-input-color: hsl(var(--mchat-base-color-2),0%,100%);
    
    --mchat-button-color: var(--mchat-primary);
    --mchat-button-text-color: var(--mchat-light-text-color);

    --mchat-feedback-value-color: var(--mchat-primary);
    --mchat-feedback-background-color: hsl(var(--mchat-base-color-1), 100%, 93%);
  }

  .page-inverse {
    --mchat-header-text-color: var(--mchat-light-text-color);
  }