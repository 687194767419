:root {
  --mchat-base-color-1: 0;
  --mchat-base-color-2: 0;
  --mchat-secondary-base-color-hsl: var(--mchat-base-color-2), 0%, 0%;
  --mchat-primary: hsl(var(--mchat-base-color-1), 76%, 45%);
  --mchat-secondary: hsl(var(--mchat-secondary-base-color-hsl));
  --mchat-terciary: #595f6e;
  --mchat-user-input-section: #8c919b;
  --mchat-light-text-color: #fff;
  --mchat-dark-text-color: #000;
  --mchat-disabled-state-color: #8c919b;
  --mchat-selected-state-color: hsl(var(--mchat-base-color-1), 100%, 35%);
  --mchat-font: Open Sans, sans-serif;
  --mchat-font-size: 1rem;
  --mchat-radius: 25px;
  --mchat-bubble-header-color: #8c919b;
  --mchat-incoming-bubble-color: #e6e6e6;
  --mchat-incoming-bubble-text-color: var(--mchat-dark-text-color);
  --mchat-outcoming-bubble-color: var(--mchat-primary);
  --mchat-outcoming-bubble-text-color: var(--mchat-light-text-color);
  --mchat-header-height: 2em;
  --mchat-header-color: var(--mchat-secondary);
  --mchat-header-text-color: var(--mchat-dark-text-color);
  --mchat-background-color: transparent;
  --mchat-panel-background-color-hsl: var(--mchat-secondary-base-color-hsl);
  --mchat-panel-text-color: var(--mchat-light-text-color);
  --mchat-input-color: hsl(var(--mchat-base-color-2), 0%, 100%);
  --mchat-button-color: var(--mchat-primary);
  --mchat-button-text-color: var(--mchat-light-text-color);
  --mchat-feedback-value-color: var(--mchat-primary);
  --mchat-feedback-background-color: hsl(var(--mchat-base-color-1), 100%, 93%);
}

.page-inverse {
  --mchat-header-text-color: var(--mchat-light-text-color);
}

.mchat-container {
  border-top: 1px solid var(--mchat-header-text-color);
  box-sizing: border-box;
  font-size: var(--mchat-font-size);
  font-family: var(--mchat-font);
  opacity: 1;
  z-index: 1;
  width: 100%;
  max-height: 130px;
  line-height: 1em;
  transition: max-height .7s linear;
  display: flex;
  overflow-y: hidden;
}

.mchat-container *, .mchat-container :before, .mchat-container :after {
  box-sizing: border-box;
}

.mchat-container--closed {
  max-height: 132px;
}

.mchat-container ::-webkit-scrollbar {
  width: .5em;
}

.mchat-container ::-webkit-scrollbar-thumb {
  background: var(--mchat-primary);
  border-radius: 20px;
}

.mchat-container ::-webkit-scrollbar-thumb:hover {
  background: var(--mchat-primary);
}

.mchat-container a {
  color: var(--mchat-primary);
}

.mchat-container--closed {
  opacity: 0;
  max-height: 0;
}

.mchat {
  background: var(--mchat-background-color);
  color: var(--mchat-dark-text-color);
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.mchat-text-with-icon {
  justify-content: center;
  align-items: center;
  gap: .25em;
  display: inline-flex;
}

.mchat-primary-color {
  color: var(--mchat-primary);
}

.mchat-time-left-icon {
  background-color: var(--mchat-primary);
  width: 1.5em;
  height: 1.5em;
  -webkit-mask: url("time-left.172b32ed.svg") center no-repeat;
  mask: url("time-left.172b32ed.svg") center no-repeat;
}

.mchat-header {
  color: var(--mchat-header-text-color);
  background-color: #0000;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: flex;
  overflow: hidden;
}

.mchat-header__title {
  padding: 1em;
}

.mchat-body {
  scroll-behavior: smooth;
  overscroll-behavior-y: contain;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  gap: .5em;
  width: 100%;
  padding-bottom: 1em;
  display: flex;
  position: relative;
  overflow-x: visible;
  overflow-y: hidden;
}

.mchat-body > * {
  max-width: 100%;
}

.mchat-body a {
  overflow-wrap: anywhere;
}

.mchat-body--avatars .mchat-message:not(.mchat-text-message.mchat-message--in):not(.mchat-typing-message) {
  padding-left: 2.3em;
}

.mchat-flex {
  flex: auto;
  justify-content: space-between;
  gap: 1em;
  display: flex;
}

.mchat-bubble {
  gap: .25em;
  display: flex;
  position: relative;
}

.mchat-bubble--in .mchat-bubble__content {
  color: var(--mchat-incoming-bubble-text-color);
  background-color: var(--mchat-incoming-bubble-color);
  border-radius: 0 var(--mchat-radius) var(--mchat-radius);
  padding: 1em;
  line-height: 1.3rem;
}

.mchat-bubble--out .mchat-bubble__content {
  color: var(--mchat-outcoming-bubble-text-color);
  background-color: var(--mchat-outcoming-bubble-color);
  border-radius: var(--mchat-radius) 0 var(--mchat-radius) var(--mchat-radius);
  padding: 1em;
  line-height: 1.3em;
}

.mchat-bubble__avatar > img {
  width: 2.5em;
  max-width: none;
}

.mchat-bubble__action-bar {
  flex-direction: column;
  align-items: flex-end;
  gap: .5px;
  display: flex;
}

.mchat-bubble__action {
  cursor: pointer;
  padding: .25rem 0;
}

.mchat-bubble__header {
  color: var(--mchat-bubble-header-color);
  padding-bottom: .25em;
}

.mchat-bubble__link {
  color: var(--mchat-primary);
  margin-top: .25em;
  display: block;
}

.mchat-image {
  max-width: 408px;
  margin-top: 8px;
}

.mchat-bubble__title {
  margin-bottom: .5em;
  font-size: 1.25em;
  font-weight: 600;
}

.mchat-input-section {
  gap: .5em;
  padding: .25em 1em .35em;
  display: flex;
  position: relative;
}

.mchat-input-section:before {
  content: "";
  opacity: .9;
  height: 2px;
  margin: 0 auto;
  display: block;
  position: absolute;
  top: -4px;
  left: 1em;
  right: 1em;
  overflow-y: visible;
  box-shadow: 0 2px 2px #000000b3;
}

.mchat-audio-loader {
  color: var(--mchat-header-color);
  border: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1em;
  font-size: 1.3em;
  font-weight: bold;
  display: flex;
}

.mchat-input-section__input {
  border-radius: var(--mchat-radius);
  color: hsl(var(--mchat-base-color-2), 0%, 0%);
  background: none;
  border: none;
  width: 100%;
  padding: .5em 0 .5em .5em;
  font-size: 1em;
}

.mchat-input-section__input:focus-visible, .mchat-input-section__input:visited {
  outline: none;
}

.mchat-input-section__input--multiline {
  resize: none;
  border-radius: 0;
  margin: .25em 0;
  padding: 0 0 0 .25em;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  line-height: 1.3em;
  overflow: hidden;
}

.mchat-input-section__input::placeholder {
  color: hsl(var(--mchat-base-color-2), 0%, 40%);
}

.mchat-action-button {
  border: 1px solid var(--mchat-button-color);
  background-color: var(--mchat-button-color);
  color: var(--mchat-dark-text-color);
  cursor: pointer;
  border-radius: 10px;
  margin-top: 8px;
  padding: 8px 16px;
  font-size: 16px;
  transition: all .3s;
  display: flex;
}

.mchat-action-button:hover {
  color: var(--mchat-light-text-color);
}

.mchat-action-button:focus {
  border: 1px solid var(--mchat-light-text-color);
}

.mchat-action-button--secondary {
  border: 1px solid var(--mchat-primary);
  color: var(--mchat-primary);
  background-color: #0000;
}

.mchat-input-section__button {
  cursor: pointer;
  background: none;
  border: none;
  justify-content: center;
  align-self: center;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
  padding: .75em;
  transition: all .3s;
  display: flex;
}

.mchat-input-section__button > svg {
  fill: var(--mchat-button-color);
  transition: all .3s;
  position: relative;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    fill: hsl(var(--mchat-base-color-1), 48%, 30%);
    opacity: .8;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.mchat-input-section__button > svg:hover {
  fill: var(--mchat-selected-state-color);
}

.mchat-input-section__button:focus {
  box-shadow: none;
  outline: none;
}

.mchat-input-section__button--audio-active > svg {
  fill: hsl(var(--mchat-base-color-1), 48%, 30%);
  transform: scale(.8);
}

.mchat-input-section__button--audio-pulsing > svg {
  animation: 1s infinite pulse;
}

.mchat-input-section__button:disabled {
  opacity: .3;
  cursor: default;
}

.mchat-input-section__button:disabled:hover {
  filter: none;
}

.mchat-input-section__button:disabled:active {
  background-color: var(--mchat-user-input-section);
}

.mchat-input-section__button.mchat-input-section__button--input-character-counter:disabled {
  opacity: 1;
}

.mchat-input-section__character-counter {
  color: red;
  padding: .7em 0;
  font-weight: bold;
}

.mchat-message {
  justify-content: center;
  align-items: center;
  gap: .5em;
  width: 100%;
  display: flex;
}

.mchat-message--out {
  flex-direction: row-reverse;
}

@media screen and (max-width: 700px) {
  .mchat-buttons-message {
    align-items: center;
    padding-left: 0;
  }
}

.mchat-hyperlinks-group-message {
  flex-flow: wrap;
  gap: .3em;
  display: flex;
}

.mchat-hyperlinks-group-message > * {
  overflow-wrap: anywhere;
  min-width: 0;
}

.mchat-search-message {
  gap: .5em;
}

.mchat-typing-message {
  width: auto;
}

.mchat-typing-message__svg {
  fill: var(--mchat-incoming-bubble-text-color);
  width: 30px;
}

@keyframes typing {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(0);
  }
}

.mchat-typing-message__circle-1 {
  animation: 1s infinite typing;
}

.mchat-typing-message__circle-2 {
  animation: 1s .2s infinite typing;
}

.mchat-typing-message__circle-3 {
  animation: 1s .4s infinite typing;
}

.mchat-debug-message {
  color: #fff;
  font-size: 90%;
}

.mchat-debug-message .mchat-bubble {
  background-color: #333131;
  flex-direction: column;
  display: flex;
}

.mchat-debug-message__title {
  vertical-align: top;
  margin: 0;
  padding: 0;
}

.mchat-debug-message__items {
  word-break: break-all;
  flex-direction: column;
  padding-inline-start: 10px;
  list-style: none;
  display: flex;
}

.mchat-closer {
  cursor: pointer;
  background: var(--mchat-header-color);
  color: inherit;
  border: none;
  padding: 1em;
}

.mchat-closer__icon {
  width: 2.5em;
  height: 2.5em;
  display: flex;
}

.mchat-opener {
  background-color: var(--mchat-primary);
  cursor: pointer;
  border: none;
  border: 1px solid var(--mchat-primary);
  border-radius: 100%;
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  width: 65px;
  height: 65px;
  margin-bottom: 0;
  margin-right: 0;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  display: flex;
  box-shadow: 0 7px 29px #64646f33;
}

@media screen and (min-width: 1300px) {
  .mchat-opener {
    border: none;
    width: 90px;
    height: 90px;
    margin-bottom: 16px;
    margin-right: 16px;
  }
}

.mchat-opener__icon svg {
  color: #fff;
  width: 3em;
  height: auto;
}

@media screen and (max-width: 1300px) {
  .mchat-opener__icon svg {
    width: 2.25em;
  }
}

.mchat-hyperlink {
  color: var(--mchat-incoming-bubble-text-color);
  gap: .5em;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 4px;
  font-style: italic;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.mchat-hyperlink:hover {
  color: hsl(var(--mchat-base-color-1), 100%, 40%);
  transform: scale(1.02);
}

.mchat .mchat-button {
  color: var(--mchat-button-text-color);
  background-color: var(--mchat-button-color);
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  border-radius: 10px;
  outline: none;
  padding: .9em 1em;
  font-size: .75em;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.mchat .mchat-button:hover {
  background-color: var(--mchat-selected-state-color);
}

.mchat .mchat-button--selected {
  background-color: var(--mchat-selected-state-color);
  color: var(--mchat-outcoming-bubble-text-color);
  pointer-events: none;
}

.mchat .mchat-icon-button {
  align-items: center;
  gap: .5em;
  display: flex;
}

.mchat .mchat-option-button:disabled:not(.mchat-button--selected) {
  background-color: var(--mchat-disabled-state-color);
  cursor: not-allowed;
}

.mchat .mchat-hyperlink-button {
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
}

.mchat-option-buttons {
  flex-direction: row;
  align-items: flex-start;
  gap: .5em;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: flex;
}

.mchat-option-buttons > * {
  width: 100%;
}

.mchat-audio {
  height: 50px;
  position: absolute;
  bottom: 3.25em;
  left: 23em;
}

.mchat-audio --steady {
  background-color: #f4d03f;
}

.mchat-audio --go {
  background-color: #28b463;
}

.mchat-user-prompt-panel {
  margin-top: var(--mchat-header-height);
  z-index: 100;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.mchat-user-prompt-panel:before {
  content: "";
  background-color: hsla(var(--mchat-secondary-base-color-hsl), .4);
  z-index: 101;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  animation: 1s both fadeIn;
  display: flex;
  position: absolute;
  inset: 0;
}

@media screen and (min-width: 700px) {
  .mchat-user-prompt-panel:before {
    border-bottom-left-radius: var(--mchat-radius);
    border-bottom-right-radius: var(--mchat-radius);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.mchat-user-prompt-panel__body {
  z-index: 102;
  text-align: center;
  background: radial-gradient(50% 50% at 50% 50%, hsla(var(--mchat-panel-background-color-hsl), 95%) 33.5%, hsl(var(--mchat-panel-background-color-hsl)) 100%);
  color: var(--mchat-panel-text-color);
  word-wrap: break-word;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5em;
  width: 90%;
  padding: 1em;
  animation: 1s both fadeIn;
  display: flex;
  position: relative;
}

.mchat-user-prompt-panel__body > * {
  filter: brightness(1.25);
}

.mchat-user-prompt-panel__prompt {
  flex-direction: column;
  gap: .25em;
  display: flex;
}

.mchat-user-prompt-panel__actions {
  gap: 10px;
  margin-top: 1em;
  display: flex;
}

.mchat-user-prompt-panel__content {
  white-space: pre-wrap;
  text-align: left;
  border-top: 1px dotted var(--mchat-primary);
  padding: .5em;
  filter: brightness(.8);
  align-self: self-start;
  width: 100%;
  padding-top: 1em;
}

.mchat-feedback-message {
  background-color: var(--mchat-feedback-background-color);
  border-color: var(--mchat-primary);
  border: 1px solid var(--mchat-primary);
  color: var(--mchat-incoming-bubble-text-color);
  border-radius: var(--mchat-radius);
  flex-direction: column;
  max-height: 120px;
  padding: 1em;
  line-height: 1.4em;
  transition: all .2s;
}

.mchat-feedback-message .mchat-feedback__values {
  margin-top: 10px;
}

.mchat-feedback-message--compact {
  background-color: inherit;
  border: none;
  border-radius: 0;
  width: auto;
  padding: .25em .25em .25em 0;
}

.mchat-feedback-message--compact .mchat-feedback__values {
  border-left: 1px solid var(--mchat-primary);
  margin-top: 0;
  padding-left: .5em;
}

.mchat-feedback-message--compact .mchat-feedback__comment {
  margin-left: 1em;
}

.mchat-feedback-message--compact.mchat-feedback-message--comment {
  width: 100%;
}

.mchat-feedback-message--comment {
  max-height: 1000px;
}

.mchat-feedback__title {
  font-weight: bold;
}

.mchat-feedback__text {
  font-style: italic;
}

.mchat-feedback__values {
  color: var(--mchat-feedback-value-color);
  justify-content: center;
  gap: 3px;
  font-size: 1.5em;
  display: flex;
  position: relative;
}

.mchat-feedback__values--editable {
  cursor: pointer;
}

.mchat-feedback__values--editable:hover .mchat-feedback__value--star {
  color: var(--mchat-selected-state-color);
}

.mchat-feedback__values--editable .mchat-feedback__value--star {
  color: var(--mchat-incoming-bubble-text-color);
  transition: color .3s;
}

.mchat-feedback__values--editable .mchat-feedback__value--star:hover {
  color: var(--mchat-selected-state-color);
}

.mchat-feedback__values--editable .mchat-feedback__value--star:hover ~ .mchat-feedback__value--star {
  color: var(--mchat-incoming-bubble-text-color);
}

.mchat-feedback__values--editable .mchat-feedback__value--thumb {
  font-size: 1.5em;
  transition: transform .2s;
}

.mchat-feedback__values--editable .mchat-feedback__value--thumb:hover {
  transform: scale(1.2);
}

.mchat-feedback__value--selected {
  color: var(--mchat-selected-state-color);
}

.mchat-feedback__value--selected.mchat-feedback__value--numeric-scale > * {
  background-color: var(--mchat-selected-state-color);
}

.mchat-feedback__values--selected {
  color: var(--mchat-selected-state-color);
  animation: .25s jump_feedback;
}

.mchat-feedback__comment {
  flex-direction: column;
  gap: .5em;
  margin-top: 10px;
  display: flex;
  position: relative;
}

.mchat-feedback__comment > * {
  margin: 0 auto;
}

.mchat-feedback__comment:before {
  content: "";
  background: var(--mchat-primary);
  width: 20%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.mchat-feedback__comment-title {
  margin-top: 5px;
  margin-bottom: 5px;
}

@keyframes jump_feedback {
  0% {
    top: 0;
  }

  30% {
    top: -10px;
  }

  60% {
    top: 0;
  }

  80% {
    top: 5px;
  }

  100% {
    top: 0;
  }
}

.mchat-search-message_buttons {
  gap: .5rem;
  display: flex;
}

/*# sourceMappingURL=base.css.map */
